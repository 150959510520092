import { MdFilterAlt } from "react-icons/md";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import React, { useEffect, useState, useRef } from "react";
import Table from "react-bootstrap/Table";
import {
  PieChart,
  Pie,
  Tooltip,
  ResponsiveContainer,
  Cell,
  Legend,
} from "recharts";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { ListGroup } from "react-bootstrap";
import logo from "../images/TP-_logo-orizz-claim-color.png";

const Dettagli = ({ showModal, handleCloseModal }) => {
  const [tpData, setTpData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [tpPieData, setTpPieData] = useState([]);
  const [tpTipoClienteData, setTpTipoClienteData] = useState([]);
  const [tpClienteData, setTpClienteData] = useState([]);
  const [tpArticoloData, setTpArticoloData] = useState([]);
  const [tpPrecodiceData, setTpPrecodiceData] = useState([]);
  const [tpFamigliaData, setTpFamigliaData] = useState([]);
  const [visiteOnline, setVisiteOnline] = useState(0);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "desc",
  });
  const articleRefs = useRef({});
  const COLORS = [
    "#b81f25",
    "#363636",
    "#dc2f2f",
    "#f0ab8d",
    "#240747",
    "#000000",
    "#d4d4d4",
    "#f0f0f0",
  ];
  const RADIAN = Math.PI / 180;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("./mancata_vendita_dashboard.json");
        const tpJsonData = res.data.TP.data;
        const tpJsonData2 = res.data.TP.visite;

        // Grouping data for TP pie chart
        const tpTipoCliGroups = tpJsonData.tipo_cliente.reduce((acc, curr) => {
          const tipoCli = curr.tipo_cli;
          const ricerche = parseInt(curr.numero_ricerche_articolo, 10);
          if (!acc[tipoCli]) {
            acc[tipoCli] = 0;
          }
          acc[tipoCli] += ricerche;
          return acc;
        }, {});

        const tpPieChartData = Object.entries(tpTipoCliGroups).map(
          ([tipoCli, count]) => ({
            name: tipoCli,
            count: count,
          })
        );

        // Aggregating data for Precodice
        const tpPrecodiceGroups = tpJsonData.articolo.reduce((acc, curr) => {
          const precodice = curr.precodice;
          const ricerche = parseInt(curr.numero_ricerche_articolo, 10);
          const nettoTotale = parseFloat(
            curr.netto_totale.replace(/[^0-9,.-]+/g, "").replace(",", ".")
          );

          if (!acc[precodice]) {
            acc[precodice] = { numero_ricerche_articolo: 0, netto_totale: 0 };
          }
          acc[precodice].numero_ricerche_articolo += ricerche;
          acc[precodice].netto_totale += nettoTotale;
          return acc;
        }, {});

        const tpPrecodiceChartData = Object.entries(tpPrecodiceGroups).map(
          ([precodice, data]) => ({
            precodice,
            numero_ricerche_articolo: data.numero_ricerche_articolo,
            netto_totale: data.netto_totale,
          })
        );

        
        const tpFamigliaGroups = tpJsonData.articolo.reduce((acc, curr) => {
          const famiglia = curr.famiglia;
          const ricerche = parseInt(curr.numero_ricerche_articolo, 10);
          const nettoTotale = parseFloat(
            curr.netto_totale.replace(/[^0-9,.-]+/g, "").replace(",", ".")
          );

          if (!acc[famiglia]) {
            acc[famiglia] = { numero_ricerche_articolo: 0, netto_totale: 0 };
          }
          acc[famiglia].numero_ricerche_articolo += ricerche;
          acc[famiglia].netto_totale += nettoTotale;
          return acc;
        }, {});

        const tpFamigliaChartData = Object.entries(tpFamigliaGroups).map(
          ([famiglia, data]) => ({
            famiglia,
            numero_ricerche_articolo: data.numero_ricerche_articolo,
            netto_totale: data.netto_totale,
          })
        );

        setTpPieData(tpPieChartData);
        setTpTipoClienteData(tpJsonData.tipo_cliente);
        setTpClienteData(tpJsonData.cliente);
        setTpArticoloData(tpJsonData.articolo);
        setTpPrecodiceData(tpPrecodiceChartData); // Update state with aggregated data for precodice
        setTpFamigliaData(tpFamigliaChartData); // Update state with aggregated data for precodice
        setVisiteOnline(tpJsonData2.visite_online);
      } catch (error) {
        console.error("Errore nel recupero dei dati JSON: ", error);
        setIsError(true);
      }
    };

    fetchData();
  }, []);

  const formatToEuro = (amount) => {
    return new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "EUR",
    }).format(amount);
  };

  const parseEuroToNumber = (amount) => {
    if (typeof amount === "string") {
      return parseFloat(amount.replace(/[^0-9,.-]+/g, "").replace(",", "."));
    } else if (typeof amount === "number") {
      return amount; // If it's already a number, return it directly
    } else {
      return 0; // If the amount is neither a string nor a number, return 0 or handle as needed
    }
  };
  

  const handleSort = (key, data, setData) => {
    let direction = "desc";
    if (sortConfig.key === key && sortConfig.direction === "desc") {
      direction = "asc";
    }

    const sortedData = [...data].sort((a, b) => {
      let aValue = a[key];
      let bValue = b[key];

      if (key === "netto_totale") {
        aValue = parseEuroToNumber(aValue);
        bValue = parseEuroToNumber(bValue);
      } else if (key === "numero_ricerche_articolo") {
        aValue = parseInt(aValue, 10);
        bValue = parseInt(bValue, 10);
      }

      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;
    });

    setSortConfig({ key, direction });
    setData(sortedData);
  };

  const handleArticleClick = (articolo) => {
    if (articleRefs.current[articolo.articolo]) {
      articleRefs.current[articolo.articolo].scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const renderSortableHeader = (label, key, data, setData) => (
    <th
      className="sticky-top  bg-light"
      onClick={() => handleSort(key, data, setData)}
      style={{ cursor: "pointer", color: "rgba(121,9,9,1)" }}
    >
      {label}
    </th>
  );
  const renderSortableHeader2 = (label, key, data, setData) => (
    <th
      className="sticky-top  bg-light"
      onClick={() => handleSort(key, data, setData)}
      style={{ cursor: "pointer", color: "rgba(121,9,9,1)" }}
    >
      {label}
    </th>
  );

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      centered
      className="rounded-0 modale-test w-100"
    >
      <Modal.Header className="bg-white">
        <Modal.Title className="text-modal mx-auto bg-transparent bg-light border border-light">
          Dettagli Mancata Vendita
          <br />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-wrap bg-white">
          <Card className="flex-card card-modale bg-light text-dark">
            <Card.Body>
              <ResponsiveContainer width="100%" height={400}>
                <PieChart>
                  <Pie
                    data={tpPieData}
                    dataKey="count"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={150}
                    labelLine={false}
                    label={({
                      cx,
                      cy,
                      midAngle,
                      innerRadius,
                      outerRadius,
                      percent,
                      name,
                    }) => {
                      const RADIAN = Math.PI / 180;
                      const radius =
                        innerRadius + (outerRadius - innerRadius) * 0.5;
                      const x = cx + radius * Math.cos(-midAngle * RADIAN);
                      const y = cy + radius * Math.sin(-midAngle * RADIAN);

                      // Label customization
                      const outerRadiusLabel = outerRadius + 20;
                      const outerX =
                        cx + outerRadiusLabel * Math.cos(-midAngle * RADIAN);
                      const outerY =
                        cy + outerRadiusLabel * Math.sin(-midAngle * RADIAN);
                      const isLeftSide = outerX < cx;
                      const maxTextWidth = 80;

                      return (
                        <>
                          <text
                            x={x}
                            y={y}
                            fill="white"
                            textAnchor="middle"
                            dominantBaseline="central"
                            style={{ pointerEvents: "none" }}
                          >
                            {`${(percent * 100).toFixed(0)}%`}
                          </text>
                          <foreignObject
                            x={isLeftSide ? outerX - maxTextWidth : outerX}
                            y={outerY - 10}
                            width={maxTextWidth}
                            height={40}
                          >
                            <div
                              style={{
                                textAlign: isLeftSide ? "right" : "left",
                                whiteSpace: "normal",
                                color: "black",
                                fontSize: "12px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {name}
                            </div>
                          </foreignObject>
                        </>
                      );
                    }}
                  >
                    {tpPieData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </Card.Body>
          </Card>

          {/*<Card
            className="flex-card card-modale bg-transparent text-dark scrollable-list"
            style={{ minHeight: "400px" }}
          >
            <Card.Body>
           
            </Card.Body>
          </Card>*/}

          <Card className="flex-card card-modale bg-light text-dark d-flex p-3">
            <Card.Title className="text-center  p-4 fs-3 fw-bold mb-0">
              NUMERO VISITE
            </Card.Title>
            <Card.Body className="d-flex flex-column justify-content-center">
              <Card.Subtitle className="mb-2 " style={{ fontSize: "5em" }}>
                {visiteOnline}
              </Card.Subtitle>
              <Card.Text className="mt-5 text-dark">
                Il dato rappresenta il numero dei clienti che hanno consultato
                il sito ma non hanno completato l'acquisto.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card className="flex-card card-modale  bg-light text-dark scrollable-list">
            <Card.Body>
              <Tabs
                defaultActiveKey="cliente"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="cliente" title="Cliente">
                  <Table>
                    <thead>
                      <tr>
                        <th className="text-dark bg-light sticky-top">
                          Regione Sociale
                        </th>
                        {renderSortableHeader(
                          "Ricerche",
                          "numero_ricerche_articolo",
                          tpClienteData,
                          setTpClienteData
                        )}
                        {renderSortableHeader(
                          "Netto Totale",
                          "netto_totale",
                          tpClienteData,
                          setTpClienteData
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {tpClienteData.map((cliente, index) => (
                        <tr
                          key={index}
                          onClick={() => handleArticleClick(cliente)}
                          style={{ cursor: "pointer" }}
                          className="bg-light fw-bold"
                        >
                          <td className="text-dark bg-light ">
                            {cliente.ragione_sociale}
                          </td>
                          <td
                            className=" bg-light"
                            style={{ color: "rgba(121,9,9,1)" }}
                          >
                            {cliente.numero_ricerche_articolo}
                          </td>
                          <td
                            className=" bg-light"
                            style={{ color: "rgba(121,9,9,1)" }}
                          >
                            {formatToEuro(cliente.netto_totale)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Tab>
                <Tab eventKey="tipoCliente" title="Tipo Cliente">
                  <Table>
                    <thead>
                      <tr>
                        <th className="text-dark bg-light sticky-top">
                          Tipo Cliente
                        </th>
                        {renderSortableHeader(
                          "Ricerche",
                          "numero_ricerche_articolo",
                          tpTipoClienteData,
                          setTpTipoClienteData
                        )}
                        {renderSortableHeader(
                          "Netto Totale",
                          "netto_totale",
                          tpTipoClienteData,
                          setTpTipoClienteData
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {tpTipoClienteData.map((tipo_cliente, index) => (
                        <tr
                          key={index}
                          onClick={() => handleArticleClick(tipo_cliente)}
                          style={{ cursor: "pointer" }}
                          className="bg-light text-dark"
                        >
                          <td className="text-dark bg-light fw-bold">
                            {tipo_cliente.tipo_cli}
                          </td>
                          <td
                            className=" bg-light"
                            style={{ color: "rgba(121,9,9,1)" }}
                          >
                            {tipo_cliente.numero_ricerche_articolo}
                          </td>
                          <td
                            className=" bg-light"
                            style={{ color: "rgba(121,9,9,1)" }}
                          >
                            {formatToEuro(tipo_cliente.netto_totale)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>

          <Card className="flex-card card-modale  bg-light text-dark scrollable-list">
            <Card.Body>
              <Tabs
                defaultActiveKey="articolo"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="articolo" title="Articolo">
                  <Table>
                    <thead>
                      <tr>
                        <th className="text-dark  bg-light sticky-top">
                          Articoli
                        </th>
                        {renderSortableHeader2(
                          "Precodice",
                          "precodice",
                          tpArticoloData,
                          setTpArticoloData
                        )}
                        <th className="text-dark  bg-light sticky-top">
                          Categoria Sconto
                        </th>
                        <th className="text-dark  bg-light sticky-top">
                          Famiglia
                        </th>
                        <th className="text-dark  bg-light sticky-top">
                          Modello
                        </th>
                        {renderSortableHeader(
                          "Ricerche",
                          "numero_ricerche_articolo",
                          tpArticoloData,
                          setTpArticoloData
                        )}
                        {renderSortableHeader(
                          "Netto Totale",
                          "netto_totale",
                          tpArticoloData,
                          setTpArticoloData
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {tpArticoloData.map((articolo, index) => (
                        <tr
                          key={index}
                          onClick={() => handleArticleClick(articolo)}
                          style={{ cursor: "pointer" }}
                        >
                          <td className="text-dark fw-bold bg-light">
                            {articolo.articolo}
                          </td>
                          <td className="text-dark  bg-light">
                            {articolo.precodice}
                          </td>
                          <td className="text-dark  bg-light">
                            {articolo.categoria_sconto}
                          </td>
                          <td className="text-dark  bg-light">
                            {articolo.famiglia}
                          </td>
                          <td className="text-dark  bg-light">
                            {articolo.modello}
                          </td>
                          <td
                            className=" bg-light"
                            style={{ color: "rgba(121,9,9,1)" }}
                          >
                            {articolo.numero_ricerche_articolo}
                          </td>
                          <td
                            className=" bg-light"
                            style={{ color: "rgba(121,9,9,1)" }}
                          >
                            {formatToEuro(articolo.netto_totale)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Tab>
                <Tab eventKey="precodice" title="Precodice">
                  <Table>
                    <thead>
                      <tr>
                        <th className="text-dark  bg-light sticky-top">
                          Precodici
                        </th>
                        {renderSortableHeader(
                          "Ricerche",
                          "numero_ricerche_articolo",
                          tpPrecodiceData,
                          setTpPrecodiceData
                        )}
                        {renderSortableHeader(
                          "Netto Totale",
                          "netto_totale",
                          tpPrecodiceData,
                          setTpPrecodiceData
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {tpPrecodiceData.map((precodice, index) => (
                        <tr
                          key={index}
                          onClick={() => handleArticleClick(precodice)}
                          style={{ cursor: "pointer" }}
                        >
                          <td className="text-dark bg-light fw-bold">
                            {precodice.precodice}
                          </td>
                          <td
                            className=" bg-light"
                            style={{ color: "rgba(121,9,9,1)" }}
                          >
                            {precodice.numero_ricerche_articolo}
                          </td>
                          <td
                            className=" bg-light"
                            style={{ color: "rgba(121,9,9,1)" }}
                          >
                            {formatToEuro(precodice.netto_totale)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Tab>
                <Tab eventKey="famiglia" title="Famiglia">
                  <Table>
                    <thead>
                      <tr>
                        <th className="text-dark bg-light sticky-top">
                          Famiglia
                        </th>
                        {renderSortableHeader(
                          "Ricerche",
                          "numero_ricerche_articolo",
                          tpFamigliaData,
                          setTpFamigliaData
                        )}
                        {renderSortableHeader(
                          "Netto Totale",
                          "netto_totale",
                          tpFamigliaData,
                          setTpFamigliaData
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {tpFamigliaData.map((famiglia, index) => (
                        <tr
                          key={index}
                          onClick={() => handleArticleClick(famiglia)}
                          style={{ cursor: "pointer" }}
                        >
                          <td className="text-dark bg-light fw-bold">
                            {famiglia.famiglia}
                          </td>
                          <td
                            className="bg-light"
                            style={{ color: "rgba(121,9,9,1)" }}
                          >
                            {famiglia.numero_ricerche_articolo}
                          </td>
                          <td
                            className="bg-light"
                            style={{ color: "rgba(121,9,9,1)" }}
                          >
                            {formatToEuro(famiglia.netto_totale)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {" "}
        <img
          src={logo}
          alt=""
          className="mx-auto p-2"
          style={{ width: "200px" }}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default Dettagli;
